import React from 'react';

function myCustom(props) {
    return (
        <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Veniam quidem distinctio quia commodi a illum voluptatibus provident itaque! Ipsam, itaque? Earum deleniti sit,
            placeat neque aspernatur beatae cum rerum optio.
        </div>
    );
}

export default myCustom;